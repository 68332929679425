import React from "react";
import { useParams } from "react-router-dom";
import { useGetNormativDetail } from "../../../../hooks/query";
import { FcCalendar } from "react-icons/fc";

const NormativDetail = () => {
  const { nmid } = useParams();

  const useGetNormativDetailList = useGetNormativDetail({
    id: nmid,
  });

  let date = new Date(useGetNormativDetailList.data?.add_time);
  let dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;

  console.log(useGetNormativDetailList);

  return (
    <div>
      <h3 className="text-center font-semibold text-2xl my-[20px]">
        {useGetNormativDetailList.data?.title}
      </h3>
      <div className="h-[100vh]">
        {useGetNormativDetailList.data?.post_file ? (
          <iframe
            height="100%"
            width="100%"
            src={useGetNormativDetailList.data?.post_file}
          />
        ) : (
          ""
        )}
      </div>
      <span className="flex items-center gap-2 py-[20px]">
        <FcCalendar size={18} />
        {dateMDY}
      </span>
    </div>
  );
};

export default NormativDetail;
