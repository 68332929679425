import React from "react";
import { useTranslation } from "react-i18next";
import zangiota from "../../assets/images/zangiota.jpg";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex justify-center items-center px-[100px] gap-8 h-[400px]">
        <div className="w-[600px]">
          <img src={zangiota} alt="" />
        </div>
        <div className="w-[865px] h-[170px]">
          <h4 className="font-[700] mb-[10px] mt-[-50px] text-[36px] text-#121221">
            {t("Zangiota tumani haqida")}
          </h4>
          <p>
            <strong className="text-[20px]">Zangiota tumani</strong> — Toshkent
            viloyatidagi tuman. 1933-yil 1-dekabrda tashkil etilgan (1992-yil
            maygacha Kalinin tumani deb atalgan).Tuman shimoldan Toshkent tumani bilan, sharqdan Toshkent
            shahrining Olmazor, Shayxontohur, Uchtepa, Chilonzor va Yangihayot
            tumanlari bilan, janubdan Yangiyo‘l tumani bilan, g‘arbdan
            Qozog‘iston Respublikasi bilan chegaradosh.Markazi — Eshonguzar
            shaharchasi. Tuman nomi shu oʻramdagi Zangiota qishlogʻi nomi bilan
            bogʻliq. Bu qishloqda 13-asr 1-yarmida Zangiota yashab oʻtgan.
            Zangiota majmuasi musulmonlarning katta ziyoratgohiga aylangan.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegionInfo;
