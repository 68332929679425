import React from "react";
import { useParams } from "react-router-dom";
import { useGetBudgetDetail } from "../../../../hooks/query";
import { FcCalendar } from "react-icons/fc";

const BudgetDetail = () => {

  const { bgid } = useParams();
  const useGetBudgetDetailInfo = useGetBudgetDetail({
    id: bgid,
  });

  let date = new Date(useGetBudgetDetailInfo.data?.add_time);
  let dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;
  return (
    <div>
      <h4 className="text-center font-semibold text-2xl my-[20px]">
        {useGetBudgetDetailInfo.data?.title}
      </h4>
      <div className="h-[100vh]">
        {useGetBudgetDetailInfo.data?.post_file ? (
          <iframe
            height="100%"
            width="100%"
            src={useGetBudgetDetailInfo.data?.post_file}
          />
        ) : (
          ""
        )}
      </div>
      <p className="flex items-center gap-2 py-[20px]">
        <FcCalendar size={22} />
        {dateMDY}
      </p>
    </div>
  );
};

export default BudgetDetail;
